import React from 'react';
import './Home.css';

function Home() {
    return (
        <div className="home-container">
            <p className="App-title2">econosim</p>
            <p className="App-subtitle">Your gateway to economic simulations</p>
        </div>
    );
}

export default Home;